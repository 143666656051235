.mainInput {
  border-style: none;
  border-radius: 1rem;
  background-color: #f5f6f7;
  padding: 1rem;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  box-shadow:
    rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
}

.badge {
  position: absolute;
  right: 8px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.badgeCircle {
  padding: 8px;
  background-color: #cae8c2;
  border-radius: 12px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
}

.newContact {
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  background-color: #e5e6e7;
}

.badgeWrapper {
  position: relative;
  display: flex;
  min-height: 36px;
  align-items: center;
}

.badgeItem {
  position: absolute;
  right: 0;
  backface-visibility: hidden;
  transition: all 0.4s ease 0s;
}

.badgeCheck {
  transform: rotateY(180deg);
}

.selected .badgeCheck {
  transform: rotateY(0);
}

.selected .badgeNewContact {
  transform: rotateY(180deg);
}
