.shareMessageArea {
  border-style: none;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #f5f6f7;
  width: 100%;
  height: 92px;
  resize: none;
}

.cardPreview {
  display: flex;
  width: 100%;
  outline: 4px solid white;
  outline-offset: -4px;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  background: rgb(237, 238, 240);
  background: linear-gradient(
    90deg,
    rgba(237, 238, 240, 1) 0%,
    rgba(245, 246, 247, 1) 58%
  );
  box-shadow: 0 4px 10px 0 #bdbaba29;
}

.cardPreviewInnerLeft {
  height: 60px;
  display: flex;
  column-gap: 1rem;
}

.cardPreviewUrl {
  box-shadow: 0 4px 10px 0 #bdbaba29;
}

.cost {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendType > p {
  color: #6b7280;
}

.backButton {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  background-color: white;
  border-radius: 12px;
  cursor: pointer;
}

.input {
  border-style: none;
  border-radius: 1rem;
  background-color: #f5f6f7;
  padding: 1rem;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  box-shadow:
    rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
    rgba(0, 0, 0, 0.9) 0px 0px 0px 1px;
}
